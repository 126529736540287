<template>
  <div class="border">
    <div class="border-item"></div>
    <div class="border-item"></div>
    <div class="border-item"></div>
    <div class="border-item"></div>
  </div>
</template>

<script>
export default {
  name: "c-border",
};
</script>

<style lang="less" scoped>
.border {
  .border-item {
    position: absolute;
    width: 24px;
    height: 23px;
    border: 2px solid #3cc1ff;
  }

  .border-item:nth-of-type(1) {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
  }

  .border-item:nth-of-type(2) {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
  }

  .border-item:nth-of-type(3) {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
  }

  .border-item:nth-of-type(4) {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
  }
}
</style>
