<template>
  <div class="navigation_bar">
    <div class="left">
      <router-link to="/page-1">监控总览</router-link>
      <router-link to="/page-2">全域监控</router-link>
    </div>
    <div class="center">改扩建施工区交通组织与工序协同优化系统</div>
    <div class="day">
      <div class="info1">
        <div class="w2">
          <span class="weather">晴</span>
          <span class="week">{{ currentDate.week }}</span>
        </div>
        <div class="date">{{ currentDate.date }}</div>
      </div>
      <div class="line"></div>
      <div class="info2">
        <div class="time">{{ currentDate.time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation-bar",
  data() {
    return {
      currentDate: {
        id: null,
        time: "",
        date: "",
        week: "",
      },
    };
  },
  computed: {
    active() {
      return this.$route.name;
    },
  },
  beforeDestroy() {
    this.currentDate.id && clearInterval(this.currentDate.id); // 在Vue实例销毁前，清除时间定时器
  },
  created() {
    this.formatDate();
    this.currentDate.id = setInterval(() => this.formatDate(), 1000);
  },
  methods: {
    formatDate() {
      const date = new Date();
      const year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
      let day = date.getDate(); // 日
      day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
      const week = date.getDay(); // 星期
      const weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.currentDate.time = `${hour}:${minute}:${second}`;
      this.currentDate.date = `${year}-${month}-${day}`;
      this.currentDate.week = weekArr[week];
    },
  },
};
</script>

<style lang="less" scoped>
.navigation_bar {
  position: relative;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .center {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #e8effb;
    background: linear-gradient(0deg, #a1e1ff 0%, #ffffff 100%);
    letter-spacing: 2px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .left {
    position: absolute;
    top: 9px;
    left: 9%;

    a {
      display: inline-block;
      width: 148px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background-image: url("@/assets/navigation-bar/button2.png");
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #108cc6;
      text-decoration: none;
    }

    .router-link-active {
      background-image: url("@/assets/navigation-bar/button1.png");
      background-size: 100% 100%;
      color: #24edff;
    }
  }

  .day {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    .line {
      width: 3px;
      height: 25px;
      background: #ffffff;
      margin: 0 10px;
    }

    .info1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      .w2 {
        margin-bottom: 8px;

        .weather {
          margin-right: 22px;
        }
      }
    }

    .info2 {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }

  &::before {
    content: "";
    width: 53%;
    height: 100%;
    background: url("@/assets/navigation-bar/navigation-bg.png") no-repeat
      center left;
    background-size: 1920px 100%;
    position: absolute;
    top: 0;
    left: -3%;
    z-index: -1;
  }

  &::after {
    content: "";
    width: 53%;
    height: 100%;
    background: url("@/assets/navigation-bar/navigation-bg.png") no-repeat
      center right;
    background-size: 1920px 100%;
    position: absolute;
    top: 0;
    right: -3%;
    z-index: -1;
  }
}
</style>
