import AMapLoader from "@amap/amap-jsapi-loader";

export function initAMapLoader() {
  window._AMapSecurityConfig = {
    securityJsCode: "d8f56c47aa13eae1ad80c53a65f9467c",
  };
  return AMapLoader.load({
    key: "c66f5bbe01698059509349b00a96947a", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: ["AMap.MoveAnimation"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    AMapUI: {
      // 重点就是这个
      version: "1.1",
      plugins: ["misc/PathSimplifier", "overlay/SimpleMarker"], // SimpleMarker设置自定义图标，PathSimplifier轨迹展示组件
    },
  });
}

export function initAMap(AMap) {
  let map = new AMap.Map("map", {
    // 设置地图容器id
    pitch: 40, // 初始化倾斜角度
    viewMode: "3D",
    zoom: 16.5, // 初始化地图级别
    // mapStyle: "amap://styles/81fc183fc996fd17493b0ef90be2994f", // 初始化自定义样式
    mapStyle: "amap://styles/darkblue", // 初始化自定义样式
    // center: [121.7608, 41.36956], // 初始化地图中心点位置
    center: [93.271519, 42.97786], // 初始化地图中心点位置
  });
  return map;
}

export const domainList = [
  [
    "93.272017,42.976312",
    "93.272173,42.976133",
    "93.272299,42.975956",
    "93.272414,42.975757",
    "93.272589,42.975392",
    "93.272659,42.97518",
  ],
  [
    "93.272878,42.975207",
    "93.272946,42.975646",
    "93.273008,42.975866",
    "93.273105,42.976102",
    "93.273261,42.976362",
    "93.273501,42.976561",
    "93.273583,42.976628",
    "93.273874,42.97683",
    "93.274314,42.976999",
    "93.27491,42.977124",
    "93.275629,42.977207",
    "93.277203,42.977401",
  ],
  [
    "93.276041,42.977605",
    "93.275773,42.97766",
    "93.275585,42.977695",
    "93.274137,42.977864",
    "93.272586,42.978113",
    "93.272248,42.978168",
    "93.271926,42.978239",
    "93.271674,42.978313",
    "93.271336,42.978435",
    "93.271089,42.978545",
    "93.2708,42.978706",
    "93.270328,42.978944",
    "93.270242,42.978996",
    "93.270006,42.979122",
    "93.269775,42.979185",
    "93.26955,42.9792",
    "93.269303,42.979196",
    "93.268995,42.979157",
    "93.268728,42.979081",
  ],
  [
    "93.268728,42.979081",
    "93.268551,42.979011",
    "93.268401,42.978901",
    "93.268246,42.978779",
    "93.268149,42.978673",
    "93.268052,42.978524",
    "93.268004,42.978387",
    "93.267977,42.97819",
    "93.268042,42.977947",
    "93.268181,42.977704",
    "93.268374,42.977453",
    "93.268594,42.977276",
    "93.268825,42.977158",
    "93.269072,42.97708",
    "93.269469,42.977037",
    "93.269925,42.977017",
    "93.270497,42.976957",
    "93.270813,42.976898",
    "93.270998,42.976837",
    "93.271245,42.976731",
    "93.272017,42.976312",
  ],
  [
    "93.265974,42.979551",
    "93.267197,42.979406",
    "93.267567,42.979414",
    "93.268045,42.979442",
    "93.268576,42.979449",
    "93.269053,42.979473",
    "93.269488,42.979446",
    "93.269912,42.979373",
    "93.270234,42.979298",
    "93.270524,42.979194",
    "93.270974,42.979026",
    "93.271478,42.978755",
    "93.271698,42.978574",
    "93.272106,42.978154",
    "93.272342,42.977825",
    "93.2725,42.977382",
    "93.272585,42.97717",
    "93.27268,42.976824",
    "93.27276,42.976384",
    "93.272878,42.975207",
  ],
  [
    "93.264657,42.979691",
    "93.264684,42.979671",
    "93.266131,42.978999",
    "93.268792,42.978143",
    "93.269473,42.977856",
    "93.270219,42.977527",
    "93.270703,42.977272",
    "93.270926,42.977142",
    "93.271166,42.976995",
    "93.271516,42.976767",
    "93.271747,42.97658",
    "93.272017,42.976312",
  ],
];
export const markerPoints = [
  { type: 1, lngLat: domainList[0][2] },
  { type: 1, lngLat: domainList[0][4] },
  { type: 2, lngLat: domainList[1][4] },
  { type: 2, lngLat: domainList[1][8] },
  { type: 3, lngLat: domainList[2][3] },
  { type: 3, lngLat: domainList[2][12] },
  { type: 4, lngLat: domainList[3][4] },
  { type: 4, lngLat: domainList[3][12] },
  { type: 5, lngLat: domainList[4][4] },
  { type: 5, lngLat: domainList[4][14] },
  { type: 6, lngLat: domainList[5][2] },
  { type: 6, lngLat: domainList[5][6] },
];
