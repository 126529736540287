<template>
  <div class="module3" id="module3">
    <div class="content1">
      <div class="title">
        <span class="chinese">交通状况</span>
        <span class="english">Traffic situation</span>
      </div>
      <div class="box">
        <div class="box-item">
          <div class="box-item-title">
            <img class="left" src="@/assets/page1/m3-title.png" alt="" />
            <span>实际通行能力</span>
            <img class="right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <dv-charts :option="option" style="width: 100%; height: 232px" />
        </div>
        <div class="box-item">
          <div class="box-item-title">
            <img class="left" src="@/assets/page1/m3-title.png" alt="" />
            <span>施工方案得分</span>
            <img class="right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="box-item-content2">
            <div class="left"><span>80</span> 综合得分</div>
            <div class="line"></div>
            <img src="@/assets/page1/score.png" alt="" />
          </div>
        </div>
        <div class="box-item">
          <div class="box-item-title">
            <img class="left" src="@/assets/page1/m3-title.png" alt="" />
            <span>方案信息</span>
            <img class="right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="bottom" src="@/assets/page1/m3-b.png" alt="" />
            <a>查看全部</a>
          </div>
          <div class="box-item-content3">
            <div v-for="item in 2" :key="item" class="box-item-content3-item">
              <div
                class="level"
                :style="{
                  backgroundColor: item % 2 === 1 ? '#DFCE10' : '#EB2727',
                }"
              >
                服务等级{{ item + 3 }}
              </div>
              <div class="box-item-content3-container">
                <div class="box-item-content3-title">2024.08.10 11:09:12</div>
                <div class="box-item-content3-content">
                  <p>
                    设置XX路段、XX路段为施工区域，经计算得到该方案通行能力为XX、服务水平为X级，综合得分为XX分。得分较{{
                      item % 2 === 1 ? "高" : "低"
                    }}，该方案{{ item % 2 === 1 ? "不" : "" }}会对交通状态有影响
                  </p>
                  <img :src="item % 2 === 1 ? levelIcon1 : levelIcon2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <border />
    <div class="flexible" id="flexible3" @click="handleFlexible">
      <img src="@/assets/gobal/flexible.png" alt="" />
    </div>
  </div>
</template>

<script>
import Border from "@/components/Border";
export default {
  name: "p1-m3",
  components: { Border },
  data() {
    const option = {
      color: ["#24EDFF"],
      grid: {
        top: 40,
        bottom: 40,
        left: 30,
        right: 30,
      },
      xAxis: {
        data: ["15min", "30min", "45min", "60min", "75min", "90min"],
        axisLabel: { style: { fill: "#FFFFFF", fontSize: 12 } },
        axisLine: { style: { stroke: "#306269", lineWidth: 1 } },
        axisTick: { show: false },
      },
      yAxis: {
        data: "value",
        axisLabel: { style: { fill: "#FFFFFF", fontSize: 12 } },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { style: { stroke: "rgba(57, 60, 64, 0.4)" } },
      },
      series: [
        {
          data: [50, 80, 90, 160, 200, 230],
          type: "line",
        },
      ],
    };
    return {
      show: true,
      option: option,
      levelIcon1: require(`@/assets/page1/m3-r1.png`),
      levelIcon2: require(`@/assets/page1/m3-r2.png`),
    };
  },
  methods: {
    handleFlexible() {
      let module = document.getElementById("module3");
      let flexible = document.getElementById("flexible3");
      this.show
        ? module.setAttribute("style", "bottom:-332px")
        : module.setAttribute("style", "bottom:20px");
      this.show
        ? flexible.setAttribute("style", "transform: rotate(90deg)")
        : flexible.setAttribute("style", "transform: rotate(-90deg)");
      this.show = !this.show;
    },
  },
};
</script>

<style lang="less" scoped>
.module3 {
  width: 1402px;
  height: 332px;
  padding: 20px 25px 0 25px;
  background: rgba(#393c40, 0.4);
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: all 0.5s;

  .content1 {
    width: 100%;
    height: 100%;
    .title {
      width: 100%;
      height: 25px;
      background-image: url("@/assets/gobal/title-bg1.png");
      background-size: 100% 100%;
      margin-bottom: 20px;

      .chinese {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-left: 31px;
        position: relative;
        top: -1px;
      }

      .english {
        font-size: 12px;
        font-family: Artifakt Element;
        font-weight: bold;
        color: rgba(#dcf4ff, 0.4);
        margin-top: 6px;
        margin-left: 6px;
      }
    }
    .box {
      width: 100%;
      height: calc(100% - 50px);
      display: flex;
      align-items: center;

      .box-item:nth-of-type(1) {
        width: 444px;
      }
      .box-item:nth-of-type(2) {
        width: 468px;
      }
      .box-item:nth-of-type(3) {
        width: calc(100% - 912px);
      }
      .box-item {
        height: 100%;
        border-right: 1px solid rgba(16, 140, 198, 0.3);

        .box-item-title {
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          //   background: rgba(#108cc6, 0.24);
          background-image: linear-gradient(
            to right,
            rgba(16, 140, 198, 0),
            rgba(16, 140, 198, 0.24),
            rgba(16, 140, 198, 0)
          );
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .left {
            width: 50px;
            height: 11px;
          }

          span {
            margin: 0 22px;
          }

          .right {
            width: 50px;
            height: 11px;
            transform: rotateY(180deg);
          }

          .bottom {
            width: 278px;
            height: 1.9px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 139px);
          }

          a {
            font-family: SourceHanSansCN;
            font-weight: 400;
            font-size: 12px;
            color: #24edff;
            line-height: 1.25;
            cursor: pointer;
            position: absolute;
            top: 8px;
            right: 10px;
          }
        }

        .box-item-content2 {
          width: 100%;
          height: calc(100% - 30px);
          padding: 20px 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
            line-height: 1;

            span {
              font-family: DIN Alternate;
              font-weight: bold;
              font-size: 44px;
              color: #ff2525;
              margin-bottom: 15px;
            }
          }

          .line {
            width: 1px;
            height: 57px;
            background: rgba(16, 140, 198, 0.3);
            margin: 0 32px;
          }

          > img {
            width: 101px;
          }
        }

        .box-item-content3 {
          width: 100%;
          height: calc(100% - 30px);
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          padding: 7px 0 9px 17px;

          .box-item-content3-item {
            width: 100%;
            height: 104px;
            display: flex;

            .level {
              width: 25px;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 0 6px;
            }

            .box-item-content3-container {
              width: calc(100% - 25px);
              height: 100%;
              .box-item-content3-title {
                height: 32px;
                background: rgba(#1388ff, 0.2);
                display: flex;
                align-items: center;
                padding: 0 8px 0 13px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                font-size: 12px;
                color: #ffffff;
                line-height: 24px;
              }

              .box-item-content3-content {
                width: 100%;
                height: calc(100% - 32px);
                padding: 0 12px;
                background: rgba(#143359, 0.54);
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                  font-family: PingFang SC;
                  font-weight: 400;
                  font-size: 12px;
                  color: #ffffff;
                  line-height: 20px;
                  margin: 0 20px 0 0;
                }

                img {
                  width: 66px;
                  height: 66px;
                }
              }
            }
          }
        }
      }
      .box-item:last-child {
        border-right: 1px solid transparent;
      }
    }
  }

  .flexible {
    width: 44px;
    height: 70px;
    position: absolute;
    top: -57px;
    left: calc(50% - 35px);
    transform: rotate(-90deg);

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 0px;
    background: rgba(6, 114, 199, 0.3);
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 0px;
    background: rgba(6, 114, 199, 0.2);
  }

  ::-webkit-scrollbar-corner {
    background: rgba(6, 114, 199, 0.2);
  }
}
</style>
