<template>
  <div class="module">
    <div class="title">
      <span>路段全域监控</span>
      <img src="@/assets/page2/title-bg.png" alt="" />
    </div>
    <div class="monitor">
      <div v-for="item in 12" :key="item" class="monitor-item">
        <img :src="require(`@/assets/video/video${item}.jpg`)" alt="" />
      </div>
    </div>
    <border />
  </div>
</template>

<script>
import Border from "@/components/Border";
export default {
  name: "p2-m1",
  components: { Border },
};
</script>

<style lang="less" scoped>
@import "../index.less";
</style>
