<template>
  <div class="container">
    <navigation-bar />

    <t-row class="row" :gutter="20">
      <t-col class="col" :flex="12"> <module1 /> </t-col>
    </t-row>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import Module1 from "./components/module1";

export default {
  name: "page-2",
  components: { NavigationBar, Module1 },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .row {
    width: 100%;
    height: calc(100% - 62px);
    padding: 20px 10px;
    margin: 0 !important;

    .col {
      height: 100%;
    }
  }
}
</style>
