import Vue from "vue";
import Router from "vue-router";
import Page1 from "@/views/Page1";
import Page2 from "@/views/Page2";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/page-1",
      name: "Page1",
      component: Page1,
    },
    {
      path: "/page-2",
      name: "Page2",
      component: Page2,
    },
    {
      path: "**",
      redirect: "/page-1",
      hidden: true,
    },
  ],
});
