import Vue from 'vue'
import App from './App.vue'
import router from './router'
import dataV from '@jiaminghi/data-view'
import TDesign from 'tdesign-vue';
import 'tdesign-vue/es/style/index.css';
import * as echarts from 'echarts'

Vue.prototype.echarts = echarts
Vue.use(TDesign);
Vue.use(dataV)
// 跨浏览器样式统一
import 'normalize.css/normalize.css'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
