<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
* {
  box-sizing: border-box !important;
}

#app {
  width: 1920px;
  height: 1220px;
  background: #000812;

  img {
    object-fit: contain;
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
