<template>
  <div class="container">
    <navigation-bar />
    <div class="content">
      <a-map
        :colors="riskLevelColors"
        :marker-type="markerType"
        :show-risk-level="showRiskLevel"
        @openConfig="openConfig"
      />
      <module1 :overview-info="overviewInfo">
        <div class="map-handle">
          <div class="search">
            <input name="name" autocomplete="off" placeholder="路段名称" />
            <img src="@/assets/page1/search.png" alt="" />
          </div>
          <div class="button-group">
            <div
              :class="{ 'button-item': true, active: markerType === 0 }"
              @click="markerType = 0"
            >
              <span>全 部</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 1 }"
              @click="markerType = 1"
            >
              <img src="@/assets/page1/marker_icon1.png" alt="" />
              <span>施工路段</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 2 }"
              @click="markerType = 2"
            >
              <img src="@/assets/page1/marker_icon2.png" alt="" />
              <span>老路利用</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 3 }"
              @click="markerType = 3"
            >
              <img src="@/assets/page1/marker_icon3.png" alt="" />
              <span>保通路段</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 4 }"
              @click="markerType = 4"
            >
              <img src="@/assets/page1/marker_icon4.png" alt="" />
              <span>监控设施</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 5 }"
              @click="markerType = 5"
            >
              <img src="@/assets/page1/marker_icon5.png" alt="" />
              <span>避险车道</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 6 }"
              @click="markerType = 6"
            >
              <img src="@/assets/page1/marker_icon6.png" alt="" />
              <span>清障车辆</span>
            </div>
          </div>
        </div>
      </module1>
      <module3 />
      <div class="risk-level-wrapper">
        <div class="switch-box">
          <div
            :class="{ switch: true, active: showRiskLevel }"
            @click="showRiskLevel = !showRiskLevel"
          ></div>
          <span>服务等级</span>
        </div>
        <div v-if="showRiskLevel" class="example-box">
          <div v-for="(item, index) in riskLevelColors" :key="item">
            <span class="color" :style="{ backgroundColor: item }"></span>
            <span class="label">服务水平{{ index + 1 }}级</span>
          </div>
        </div>
      </div>
    </div>

    <t-dialog
      width="550px"
      top="8vh"
      :visible.sync="isVisible"
      :closeBtn="null"
      :cancelBtn="null"
      :confirmBtn="null"
    >
      <span class="close" @click="onReset"></span>
      <p>路段配置</p>
      <t-form
        ref="form"
        :data="overviewForm"
        labelWidth="110px"
        @reset="onReset"
        @submit="onSubmit"
      >
        <t-form-item label="车道数" name="laneCount">
          <t-input-adornment append="条">
            <t-input-number
              v-model="overviewForm.laneCount"
              theme="normal"
              :min="0"
            />
          </t-input-adornment>
        </t-form-item>
        <t-form-item label="车道宽度" name="laneWidth">
          <t-input-adornment append="米">
            <t-input-number
              v-model="overviewForm.laneWidth"
              theme="normal"
              :min="0"
              :decimalPlaces="2"
            />
          </t-input-adornment>
        </t-form-item>
        <t-form-item label="左侧路缘带" name="leftCurbBelt">
          <t-input-number
            v-model="overviewForm.leftCurbBelt"
            theme="normal"
            :min="0"
            :decimalPlaces="2"
          />
        </t-form-item>
        <t-form-item label="右侧路肩" name="rightShoulder">
          <t-input-number
            v-model="overviewForm.rightShoulder"
            theme="normal"
            :min="0"
          />
        </t-form-item>
        <t-form-item label="设计速度" name="designSpeed">
          <t-input-adornment append="km/h">
            <t-input-number
              v-model="overviewForm.designSpeed"
              theme="normal"
              :min="0"
            />
          </t-input-adornment>
        </t-form-item>
        <t-form-item>
          <t-button theme="primary" type="submit">提交</t-button>
          <t-button theme="default" variant="base" type="reset">
            取消
          </t-button>
        </t-form-item>
      </t-form>
    </t-dialog>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import AMap from "./components/map";
import Module1 from "./components/module1";
import Module3 from "./components/module3";

export default {
  name: "page-1",
  components: {
    NavigationBar,
    AMap,
    Module1,
    Module3,
  },
  data() {
    return {
      isVisible: false,
      overviewForm: {
        laneCount: 2,
        laneWidth: 3.75,
        leftCurbBelt: 0.75,
        rightShoulder: 2,
        designSpeed: 120,
      },
      overviewInfo: {
        laneCount: 2,
        laneWidth: 3.75,
        leftCurbBelt: 0.75,
        rightShoulder: 2,
        designSpeed: 120,
      },

      markerType: 0,
      showRiskLevel: true,
    };
  },
  computed: {
    riskLevelColors() {
      return ["#0E7400", "#34FF19", "#DFCE10", "#EB2727"];
    },
  },
  methods: {
    openConfig() {
      this.overviewForm = { ...this.overviewInfo };
      this.isVisible = true;
    },
    onSubmit({ validateResult }) {
      if (validateResult !== true) return;

      this.$message.success("路段配置成功");
      this.overviewInfo = { ...this.overviewForm };
      this.onReset();
    },
    onReset() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .content {
    width: 100%;
    height: calc(100% - 62px);
    padding: 20px;
    position: relative;
  }

  .map-handle {
    width: 400px;
    position: absolute;
    top: 0;
    right: -420px;

    .search {
      width: 100%;
      position: relative;
      input {
        width: 100%;
        height: 32px;
        padding: 0 30px 0 15px;
        line-height: 32px;
        background: linear-gradient(0deg, #002040, #00326b);
        border: 1px solid #1c709e;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }

      input::placeholder {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }

      input:focus {
        outline: none;
      }

      img {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .button-group {
      width: 100%;

      .button-item {
        width: 214px;
        height: 42px;
        background-image: url("@/assets/gobal/bg-choose.png");
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;

        img {
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #24edff;
        }

        &.active {
          background-image: url("@/assets/gobal/bg-choosed.png");
        }
      }
    }
  }

  .risk-level-wrapper {
    width: 120px;
    position: absolute;
    top: 62px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .switch-box {
      width: 120px;
      height: 32px;
      background: linear-gradient(0deg, #002040, #00326b);
      border-radius: 4px;
      border: 1px solid #1c709e;
      display: flex;
      justify-content: center;
      align-items: center;

      .switch {
        width: 28px;
        height: 10px;
        background: #d8f2ff;
        border-radius: 5px;
        position: relative;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          background: #35e4ff;

          &::before {
            left: calc(100% - 14px);
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: -2px;
          left: 0;
          width: 14px;
          height: 14px;
          background: #fdfdfd;
          box-shadow: 0 0 4px 0 #044b5f;
          border-radius: 50%;
          transition: left 0.2s;
        }
      }

      > span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #fdfdfd;
        line-height: 20px;
      }
    }

    .example-box {
      width: 104px;
      height: 130px;
      background: rgba(4, 75, 95, 0.7);
      border-radius: 4px;
      border: 1px solid rgba(4, 75, 95, 0.7);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 2px 0;
      margin-top: 10px;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;

        .color {
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }

        .label {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }
  }
}

::v-deep .t-dialog {
  background: url("@/assets/page1/bg.png") no-repeat center;
  background-size: 100% 100%;
  border: none;
  padding: 0;

  .t-dialog__header,
  .t-dialog__footer {
    display: none;
  }

  .t-dialog__body {
    padding: 0 20px 40px;

    .close {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 28px;
      top: 28px;
      cursor: pointer;
    }

    > p {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 18px;
      color: #a1e1ff;
      line-height: 24px;
      background: url("@/assets/page2/title-bg.png") no-repeat center;
      background-size: 100% 100%;
      text-align: center;
      margin: 36px 0 30px;
      padding-bottom: 14px;
    }

    .t-form {
      padding-right: 20px;

      .t-form__item {
        .t-form__label {
          color: #fff;
          padding-right: 12px;
        }

        .t-form__controls {
          .t-input-adornment {
            width: 100%;

            .t-input-number {
              flex: 1;
              padding-right: 0;
            }

            .t-input-adornment__append {
              background: transparent;

              .t-input-adornment__text {
                width: 52px;
                color: #fff;
                border-color: transparent;
              }
            }
          }

          .t-input-number {
            flex: 1;
            padding-right: 52px;

            .t-input {
              background: #0b1c2a;
              border-color: #1c709e;
              box-shadow: none;

              .t-input__inner {
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 14px;
                color: #ffffff;
                line-height: 24px;
                text-align: center;
              }
            }
          }
        }

        &:last-child .t-form__controls-content {
          justify-content: center;

          .t-button {
            width: 80px;
            border-radius: 4px;
            color: #fff;
            border-color: transparent;
            margin: 0 10px;

            &.t-button--theme-primary {
              background: linear-gradient(0deg, #22dcfd, #1c91e2, #5865b9);
            }

            &.t-button--theme-default {
              background: #074593;
            }
          }
        }
      }
    }
  }
}
</style>
